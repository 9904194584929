import React, { useState } from 'react'
import {Player} from '@remotion/player'
import { Video } from './videos'
import { Stack, Input, FormControl, FormLabel } from "@chakra-ui/react"

function RemotionDemo() {
    const [name, setName] = useState('')

    function handleNameChange(e) {
        setName(e.target.value)
    }

    return (
        <Stack w="full">
            <Player
                component={Video}
                compositionWidth={1280}
                compositionHeight={720}
                controls
                durationInFrames={120}
                fps={30}
                inputProps={{
                    name,
                }}
                style={{
                    width: "100%",
                }}
                loop
            />
            <FormControl id="name">
                <FormLabel>Enter Your Name Here 👇</FormLabel>
                <Input
                    variant="filled"
                    id="name"
                    size="lg"
                    value={name}
                    onChange={handleNameChange}
                    maxLength={28}
                    name="Name"
                    placeholder="John Doe"
                />
            </FormControl>
        </Stack>
    )
}

export { RemotionDemo }