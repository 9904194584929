import React from "react"
import { AbsoluteFill, Sequence } from "remotion"
import { Name } from "./sequences/Name"

export const Video: React.FC<{
    name: string
}> = ({  name }) => {

    return (
        <AbsoluteFill>
            <Sequence from={0} durationInFrames={120}>
                <Name name={name} />
            </Sequence>
        </AbsoluteFill>
    )
}
