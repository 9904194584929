import React, { useEffect, useState } from "react"
import { Box, Heading, Center, Image, Flex, usePrefersReducedMotion } from "@chakra-ui/react"
import {
    interpolate,
    spring,
    useCurrentFrame,
    useVideoConfig,
    delayRender,
    continueRender,
} from "remotion"
import HandWave from '../assets/hand-wave.gif'
import hellos from '../hello'
import { useLocalStorage } from "../../../hooks/useLocalStorage"

export const Name: React.FC<{
    name: string
}> = ({ name }) => {
    const { fps } = useVideoConfig()
    const frame = useCurrentFrame()
    const progress = spring({
        fps,
        frame,
        config: {
            damping: 200,
        },
    })

    const prefersReducedMotion = usePrefersReducedMotion()

    const [handle] = useState(() => delayRender())

    const [userInfo, setUserInfo] = useLocalStorage('user-info', null)

    useEffect(() => {
        if(!userInfo) {
             fetch(
                 `https://api.ipdata.co?api-key=be4ba05834558081f89a7663e07a35ec543ea10549db438fb642dc0b`
             )
                 .then(res => res.json())
                 .then(function (res) {
                     continueRender(handle)
                     //  @ts-expect-error
                     if (res?.languages?.[0]?.name) setUserInfo(res)
                 })
        }
    }, [])

    return (
        <Center
            bg="blue.200"
            color="black"
            w="full"
            h="full"
            _dark={{
                bg: "blue.900",
                color: "white",
            }}
        >
            <Flex
                mx={40}
                direction="column"
                align="center"
                style={{
                    gap: "8px",
                    transform: prefersReducedMotion ? 'none' : `translateY(${interpolate(
                        progress,
                        [0, 1],
                        [1000, 0]
                    )}px)`,
                }}
            >
                <Heading fontSize="6xl" textAlign="center">
                    {hellos[userInfo?.languages?.[0]?.name || "English"]}
                    {name ? ` ${name}` : ""}!
                </Heading>
                <Box boxSize="xs">
                    <Image src={HandWave} alt="hand wave" />
                </Box>
            </Flex>
        </Center>
    )
}
